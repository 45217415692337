.successModalOverlay {
    z-index: 9;
    inset: 0px;
    display: flex;
    outline: none;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: #00000070;
}

.successmodal {
    width: 80vw;
    max-height: 60vh;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 12px;
    padding: 5px 10px;
}

.successmodal:focus-visible {
    outline: none !important
}

.successmodal .errorwrapper {
    width: 100%;
}

.warningOrAlert {
    width: 100%;
}

.warningOrAlert .lottiewrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.warningOrAlert .successmsg {
    text-align: center;
    font-size: 12pt;
    font-weight: 500;
}

.warningOrAlert .successlottie {
    width: 150px;
    height: 150px;
}

.warningOrAlert .buttons {
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

.warningOrAlert .buttons button {
    background-color: #28a745;
    color: white;
    padding: 8px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

@media(min-width:400px) {
    .successmodal {
        width: 60vw;
    }
}

@media(min-width:600px) {
    .successmodal {
        width: 40vw;
        min-height: auto;
    }
}

@media(min-width:900px) {
    .successmodal {
        width: 30vw;
        min-height: auto;
    }
}

@media(min-width:1300px) {
    .successmodal {
        width: 20vw;
        min-height: auto;
    }
}