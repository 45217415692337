*{
    margin: 0;
    padding: 0;
}

.mainDashboardDiv {
    position: relative;
    width: 90vw;
    margin: 0 auto;
}

.headerDiv{
    position: sticky;
    z-index: 99;
    left: 0;
    right: 0;
    top: 0;
    padding: 10px 40px;
    transition: all .4s ease-out;
    background: #fff; 
    box-shadow: 1px 0px 5px 0px rgb(25 164 99 / 31%);
}

.heroDiv{
    padding-bottom: 20px;
}