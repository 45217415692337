.headerMainDiv {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.logoImage {
    width: 15%;
    text-align: center;
}

/* .logoImage .poweredBy {
    display: block;
    color: #01774A;
    width: 100%;
    font-size: 12px;
    text-align: center;
} */

/* .logoImage img {
    width: 35%;
    display: block;
    margin: 0 auto;
} */

.menuMainDiv {
    width: 45%;
}

/* .menuMainDiv .nav-Items{
    display: flex;
    align-items: center;
    width:100%;
    list-style: none;
    justify-content: space-between;
    margin: 0;
    padding: 0;
} */

.userdiv {
    width: 15%;
}

.userdiv .longinBtn {
    font-family: "Inter", Sans-serif;
    font-size: 14px !important;
    font-weight: 600 !important;
    text-transform: uppercase;
    color: #fff;
    background-color: #FC7800 !important;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #FC7800;
    border-radius: 25px;
    padding: 10px 20px;
    cursor: pointer;
    transition: all .4s ease-in-out;
}

.longinBtn:hover {
    color: #FC7800;
    background-color: #fff !important;
    border-color: #FC7800;
    transition: all .4s ease-in-out;
}
.menuMainDiv .nav-Items .nav-Item:hover{
    color: #01774a;
    transition: all .3s ease-in-out;
}

.menuMainDiv .nav-Items .nav-Item:hover::before {
    transform: scale(1, 1);
    opacity: 1;
}

.menuMainDiv .nav-Items .nav-Item {
    position: relative;
    color: #505050;
    font-size: 16px;
    font-weight: 500;
    padding: 5px;
    cursor: pointer;
}

.menuMainDiv .nav-Items .nav-Item:before {
    content: '';
    position: absolute;
    width: 70%;
    height: 3px;
    background: #01774a;
    border-radius: 20px;
    left: 5px;
    bottom: 0;
    transform: scale(0, 1);
    transform-origin: 0 100%;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

/* -------------------------------------  Responsive Design ---------------------------------------------  */

.menuMainDiv .nav-Items {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
}

.hammenuIcon {
    background: transparent;
    border: none;
}

@media(min-width:320px) {

    .headerMainDiv {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        position: relative;
    }

    .headerMainDiv .logoImage {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .logoImage img {
        width: 35%;
        display: block;
        margin: 0 auto;
    }

    .logoImage .poweredBy {
        display: block;
        color: #01774A;
        width: 100%;
        font-size: 9px;
        text-align: center;
    }

    .headerMainDiv .nav-Items {
        display: none;
    }

    .headerMainDiv .mobileNav {
        width: 115px;
        position: absolute;
        top: 10vh;
        z-index: 999;
    }

    .headerMainDiv .mobileNav .mob-navwrapper {
        width: 100%;
        background: #ffffff;
        text-align: left;
    }

    .headerMainDiv .mobileNav .mob-navwrapper .mobilenav_item {
        padding:10px;
        color: #505050;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }

    .headerMainDiv .userdiv.bigscreen {
        display: none !important;
    }

    .headerMainDiv .mobilelogin {
        font-family: "Inter", Sans-serif;
        font-size: 12px !important;
        font-weight: 600 !important;
        text-transform: uppercase;
        color: #fff;
        background-color: #FC7800 !important;
        border-style: solid;
        border-width: 2px 2px 2px 2px;
        border-color: #FC7800;
        border-radius: 25px;
        padding: 5px 20px;
        margin: 5px;
        cursor: pointer;
    }

    .ModalCss {
        display: block;
        padding: 1rem;
        width: 80%;
        max-width: 80%;
        background-color: #fff;
        border-radius: 15px;
        outline: transparent;
        transition: all 0.5s ease-in-out;
        animation: show 0.2s;
        transform: scale(1);
        overflow: hidden;
    }

    .modalBody {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    .instituteDiv {
        width: 100%;
        text-align: center;
        border-radius: 5px;
        padding: 10px 0;
        transition: all .5s ease-in-out;
        border: 1px solid #fff;
        cursor: pointer;
    }
}

@media(min-width:400px) {
    .headerMainDiv .mobileNav .mob-navwrapper {
        width: 100%;
        background: #ffffff;
        text-align: left;
    }

    .ModalCss {
        display: block;
        padding: 1rem;
        width: 80%;
        max-width: 80%;
        background-color: #fff;
        border-radius: 15px;
        outline: transparent;
        transition: all 0.5s ease-in-out;
        animation: show 0.2s;
        transform: scale(1);
        overflow: hidden;
    }

    .modalBody {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    .instituteDiv {
        width: 100%;
        text-align: center;
        border-radius: 5px;
        padding: 10px 0;
        transition: all .5s ease-in-out;
        border: 1px solid #fff;
        cursor: pointer;
    }
}


@media(min-width:425px) {
    .headerMainDiv .logoImage {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .logoImage img {
        width: 20%;
        display: block;
        margin: 0 auto;
    }

    .logoImage .poweredBy {
        display: block;
        color: #01774A;
        width: 100%;
        font-size: 9px;
        text-align: center;
    }

    .headerMainDiv .mobileNav .mob-navwrapper {
        width: 100%;
        background: #ffffff;
        text-align: left;
    }

    .ModalCss {
        display: block;
        padding: 1rem;
        width: 50%;
        max-width: 80%;
        background-color: #fff;
        border-radius: 15px;
        outline: transparent;
        transition: all 0.5s ease-in-out;
        animation: show 0.2s;
        transform: scale(1);
        overflow: hidden;
    }

    .modalBody {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

}

@media(min-width:600px) {
    .headerMainDiv .logoImage {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .logoImage img {
        width: 10%;
        display: block;
        margin: 0 auto;
    }

    .logoImage .poweredBy {
        display: block;
        color: #01774A;
        width: 100%;
        font-size: 9px;
        text-align: center;
    }

    .ModalCss {
        display: block;
        padding: 1rem;
        width: 40%;
        max-width: 80%;
        background-color: #fff;
        border-radius: 15px;
        outline: transparent;
        transition: all 0.5s ease-in-out;
        animation: show 0.2s;
        transform: scale(1);
        overflow: hidden;
    }

}


@media(min-width:768px) {
    .headerMainDiv {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }

    .headerMainDiv .hamburgericon {
        display: none;
    }

    .headerMainDiv .nav-Items {
        width: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5%;
        list-style: none;
    }


    .headerMainDiv .mobileNav {
        display: none;
    }

    .headerMainDiv .logoImage {
        width: 10%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .logoImage img {
        width: 35%;
        display: block;
        margin: 0 auto;
    }

    .logoImage .poweredBy {
        display: block;
        color: #01774A;
        width: 100%;
        font-size: 9px;
        text-align: center;
    }

    .headerMainDiv .userdiv.bigscreen {
        display: block !important;
    }

    .ModalCss {
        display: block;
        padding: 1rem;
        width: 30%;
        max-width: 80%;
        background-color: #fff;
        border-radius: 15px;
        outline: transparent;
        transition: all 0.5s ease-in-out;
        animation: show 0.2s;
        transform: scale(1);
        overflow: hidden;
    }
}


/* -------------------------------------  Responsive Design End ---------------------------------------------  */




/* Modal CSS */

/* .ModalCss {
    display: block;
    padding: 1rem;
    width: 35%;
    max-width: 80%;
    background-color: #fff;
    border-radius: 15px;
    outline: transparent;
    transition: all 0.5s ease-in-out;
    animation: show 0.2s;
    transform: scale(1);
    overflow: hidden;
} */

.ModalBtn {
    font-weight: 500;
    padding: 7px 20px;
    color: #FFFFFF;
    outline: none;
    border: none;
    margin-top: 20px;
    background: transparent linear-gradient(180deg, #A7D63F 0%, #9FD340 7%, #39B54A 100%) 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    width: 80px;
    cursor: pointer;
}

.MsgDiv {
    margin: 10px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modalOverlayStyle {

    position: fixed;
    top: 0;
    right: 0;
    z-index: 999999;
    bottom: 0;
    left: 0;
    background-color: #060f1996;
    animation: show 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(1);
}

.modalHeader {
    position: relative;
    padding: 10px;
}

.modalHeader h2 {
    margin: 0;
    text-align: center;
    font-size: 1.5rem;
}

.Close_Button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #DFFDF0;
    border: none;
    padding: 15px;
    border-radius: 50px;
    font-size: 1.8rem;
    height: 35px;
    color: #01774A;
    cursor: pointer;
    width: 35px;
}

/* .modalBody {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
} */

/* .instituteDiv {
    width: 35%;
    text-align: center;
    border-radius: 5px;
    padding: 10px 0;
    transition: all .5s ease-in-out;
    border: 1px solid #fff;
    cursor: pointer;
} */

.instituteDiv:hover {
    background-color: #EEFFF9;
    transition: all .5s ease-in-out;
    border: 1px solid #01774A;
}

@keyframes highlightBorder {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.studentDiv {
    background-color: #EEFFF9;
    border: 1px solid transparent;
    /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
    background: linear-gradient(270deg, #EEFFF9, #81d18e, #EEFFF9);
    background-size: 400% 400%;
    animation: highlightBorder 4s ease-in-out infinite;
    transition: all .5s ease-in-out;
    background-size: 200% 200%;
    /* Ensures the gradient covers the entire border area */
}

.studentDiv:hover {
    background: #EEFFF9;
    transition: all .5s ease-in-out;
    border: 1px solid #01774A;
}