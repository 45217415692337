.pricacyPolicyBox p {
    text-align: left;
    font-size: 14px;
    line-height: 20px;
}
.pricacyPolicyBox{
    width: 85%;
    margin: 10px auto;
    padding: 0 20px;
    border: 1px solid #01774a;
    border-radius: 5px;
}
.pricacyPolicyBox a,.activePrivacy{
    color: #01774a;
    font-weight: 600;
    text-decoration: none;
}
.pricacyPolicyBox ul{
    padding: 0;
}
.pricacyPolicyBox ul li{
    text-align: left;
    list-style-type: none;
    margin: 10px 0;
    font-size: 15px;
}
.pricacyPolicyBox h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    color: #01774a;
    text-align: left;
}
.pricacyPolicyBox h4{
    text-align: left;
    font-size: 1.2rem;
}