.footer_sec {
    padding-bottom: 10px;
    background-color: #EEFFF9;
    color: #01774a;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
}

.footer1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.categ a {
    text-decoration: none;
    color: #047c4d;
}


/* .footer-item { 
    margin: 10px 0 0;
    width: 24%;
    text-align: left;
} */
.logo {
    text-align: center;
}

/* .footerMenu {
    width: 15%;
} */

.logo img {
    width: 32%;
}

.content h5 {
    margin-top: 20px;
}

.social-icons ul {
    list-style: none;
    padding: 0;
    display: flex;
    margin-top: 20px;
}

.social-icons ul li {
    margin-right: 10px;
}

.social-icons ul li a {
    color: #fff;
    font-size: 20px;
}

.categ h4 {
    margin-bottom: 5px;
    font-size: 15px;
}

.compH4 {
    margin-bottom: 0px !important;
}


.categ ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footerMenu ul li {
    color: #01774a;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
}

.categ p {
    font-size: 14px;
    margin: 5px 0;
}

.newsletter input[type="text"],
.newsletter input[type="email"] {
    width: 90%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    outline: none;
    border: none;
    background-color: #fff;
}

.news-btn {
    background-color: #28a745;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.news-btn img {
    margin-right: 10px;
}

.footer-bottom {
    text-align: center;
}

.copy h5 {
    font-size: 11px;
    margin: 0;
}


@media(min-wdith:320px) {
    .footer-item {
        margin: 10px 0 0;
        width: 100%;
        text-align: left;
    }
}

@media(min-width:700px) {
    .footer-item {
        margin: 10px 0 0;
        width: 24%;
        text-align: left;
    }
}