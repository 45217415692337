*{
    margin: 0;
    padding: 0;
}

.loadermodal{
    width: 30vw;
    max-height: 60vh;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loadermodal:focus-visible{
    outline: none !important;
}

.loaderModalOverlay{
    z-index: 9;
    inset: 0px;
    display: flex;
    outline: none;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: #00000070;
}

.loadermodal .loaderwrapper{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loaderwrapper .loaderfile{
    margin: 0 auto;
    width: 20%;
}