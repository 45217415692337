/* src/Companies.css */
.header {
    background: linear-gradient(to right, #1d7a4e, #0d2f7d);
    color: white;
    padding: 20px;
    text-align: center;
    border-radius: 5px;
}

.search-bar {
    margin: 20px 0;
    display: flex;
    justify-content: center;
}

.search-bar input {
    padding: 10px;
    width: 50%;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.companies-list {
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.institute-card {
    background: white;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 15%;
    text-align: center;
}

.institute-card img {
    max-width: 100%;
    height: 100px;
    margin-bottom: 10px;
    object-fit: contain;
}

.institute-card p { 
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
}
