* {
    margin: 0;
    padding: 0;
}


.contactuswrapper {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.contactus .splitscreen {
    display: flex;
    flex-direction: column;
}

.contactus .leftside {
    background: url('../../Assets//abstract-green-background.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.contactus .rightside .formheading .formtitle span {
    font-size: 24px;
    font-weight: bold;
}

.contactus .rightside .formfields .fieldwrapper .fieldrow .name,
.contactus .rightside .formfields .fieldwrapper .fieldrow .email,
.contactus .rightside .formfields .fieldwrapper .fieldrow .phone,
.contactus .rightside .formfields .fieldwrapper .fieldrow .college,
.contactus .rightside .formfields .fieldwrapper .fieldrow .designation,
.contactus .rightside .formfields .fieldwrapper .fieldrow .message {
    width: 100%;
}


.contactus .rightside .formfields .fieldwrapper .fieldrow .phone,
.contactus .rightside .formfields .fieldwrapper .fieldrow .college,
.contactus .rightside .formfields .fieldwrapper .fieldrow .designation {
    width: 100%;
    margin-bottom: 10px;
}

.contactus .rightside .formfields .fieldwrapper .fieldrow .fieldlabel {
    text-align: left;
}

.contactus .rightside .formfields .fieldwrapper .fieldrow .fieldlabel label {
    font-weight: 500;
    font-size: 10pt;
}

.contactus .rightside .formfields .fieldwrapper .fieldrow .fieldlabel .star {
    color: red;
}

.contactus .rightside .formfields .fieldwrapper .fieldrow .fieldinput input,
.contactus .rightside .formfields .fieldwrapper .fieldrow .fieldinput textarea {
    width: 100%;
    margin: 2px 0;
    padding: 8px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 10pt;
    text-indent: 1vw;
}

.contactus .rightside .formfields .fieldwrapper .fieldrow .fieldinput input:focus-visible,
.contactus .rightside .formfields .fieldwrapper .fieldrow .fieldinput textarea:focus-visible ,
.contactus .rightside .formfields .fieldwrapper .fieldrow .fieldinput select:focus-visible {
    outline: 1px solid #484444;
}

.contactus .rightside .formfields .fieldwrapper .fieldrow .phone .fieldinput {
    border: 1px solid #ccc;
    border-radius: 5px;
}

.contactus .rightside .formfields .fieldwrapper .fieldrow .phone .fieldinput input {
    border: none;
}

.contactus .rightside .formfields .fieldwrapper .fieldrow .phone .fieldinput select {
    padding:0;
    border-bottom: none;
    border-left: none;
    border-top: none;
    border-right: 1px solid #ccc;
}

.contactus .rightside .formfields .fieldwrapper .fieldrow .fieldinput input::placeholder,
.contactus .rightside .formfields .fieldwrapper .fieldrow .fieldinput textarea::placeholder {
    font-size: 10pt;
}

.contactus .rightside .formfields .fieldwrapper .fieldrow .fieldinput textarea {
    height: 100px;
}

.contactus .rightside .formfields .fieldwrapper .fieldrow .phone .fieldinput {
    display: flex;
    align-items: center;
}

.contactus .rightside .formfields .fieldwrapper .fieldrow .actionButtons {
    width: 100%;
}

.contactus .rightside .formfields .fieldwrapper .fieldrow .actionButtons .btnwrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.contactus .rightside .formfields .fieldwrapper .fieldrow .actionButtons .btnwrapper .resetbtn button,
.contactus .rightside .formfields .fieldwrapper .fieldrow .actionButtons .btnwrapper .submitBtn button {
    padding: 8px 20px;
    border: none;
    border-radius: 12px;
    font-size: 16px;
    cursor: pointer;
}

.contactus .rightside .formfields .fieldwrapper .fieldrow .actionButtons .btnwrapper .resetbtn button {
    background-color: #ffffff;
    border: 2px solid #28a745;
    color: #28a745;
}

.contactus .rightside .formfields .fieldwrapper .fieldrow .actionButtons .btnwrapper .submitBtn button {
    background-color: #28a745;
    color: white;
}

.contactus .rightside .formfields .error {
    height: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.contactus .rightside .formfields .errorfield {
    display: block;
    font-size: 8pt;
    color: red;
}

/* Responsive Layouts */
@media (min-width: 768px) {
    .contactus .splitscreen {
        flex-direction: row;
        justify-content: space-between;
    }

    .contactus .leftside {
        display: block;
        width: 40%;
        background-color: #f9f9f9;
        padding: 20px;
    }

    .contactus .rightside {
        width: 55%;
        padding-left: 10px;
    }

    .contactus .rightside .formfields .fieldwrapper .fieldrow .name .fieldinput {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
    }

    .contactus .rightside .formfields .fieldwrapper .fieldrow .name .fieldinput .firstname,
    .contactus .rightside .formfields .fieldwrapper .fieldrow .name .fieldinput .lastname {
        width: 48%;
    }

    .contactus .rightside .formfields .fieldwrapper .fieldrow .email,
    .contactus .rightside .formfields .fieldwrapper .fieldrow .phone,
    .contactus .rightside .formfields .fieldwrapper .fieldrow .college,
    .contactus .rightside .formfields .fieldwrapper .fieldrow .designation {
        width: 100%;
    }

    .contactus .rightside .formfields .fieldwrapper .fieldrow .phone .PhoneInputCountry {
        left: 5px;
    }

    .contactus .rightside .formfields .fieldwrapper .fieldrow .message {
        width: 100%;
    }

    .contactus .rightside .formfields .fieldwrapper .fieldrow .actionButtons {
        width: 100%;
    }
}

@media (min-width: 1024px) {
    .contactuswrapper {
        padding: 40px;
    }

    .contactus .rightside .formfields .fieldwrapper .fieldrow .actionButtons .btnwrapper .resetbtn button,
    .contactus .rightside .formfields .fieldwrapper .fieldrow .actionButtons .btnwrapper .submitBtn button {
        font-size: 18px;
    }
}