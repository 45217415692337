/* WhatsAppChat.css */
.whatsapp-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .chat-window {
    backdrop-filter: blur(4px);
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 15px;
    width: 300px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
    transform: scale(0);
    position: relative;
    bottom: 70px;
    right: 40px;
  }
  
  .chat-window.open {
    transform: scale(1);
  }
  
  .whatsapp-toggle {
    background-color: #25D366;
    color: white;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    bottom: 0px;
    right: 10%;
  }
  
  .whatsapp-icon {
    font-size: 30px;
  }
  
  .chat-button {
    border: 1px solid rgb(235, 235, 235);
    border-radius: 30px;
    color: rgb(255, 255, 255);
    width: 50%;
    margin: 0 auto;
    padding: 10px 24px;
    font-size: 16px;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    gap: 10px;
    margin-top: 10px;
    background: linear-gradient(rgb(18, 198, 101) 0%, rgb(0, 148, 70) 100%);
  }
  
  .chat-button:hover {
    background-color: #20b358;
  }
  