.homeHeroDiv {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.31), rgba(25, 164, 99, 0.31)), url('../../Assets/CampusWomanChat.jpg');
    background-size: cover;
    background-position: center;
    height: 330px;
    display: flex;
    align-items: flex-end;
    background-repeat: no-repeat;
    justify-content: flex-end;
}
.homeTextDiv span{
    margin: 5px;
    color: #fff;
    font-size: 2rem;
}
.homeTextDiv h2{
    margin: 0;
    color: #fff;
    font-size: 3.2rem;
}
.homeTextDiv{
    width: 100%;
    text-align: right;
    padding: 10px 20px;
    background: linear-gradient(to bottom, transparent, rgba(25, 164, 99, 0.381));
}
.AccessDivRoleWise {
    display: flex;
    justify-content: center;
    padding:10px;
    background: #01774a;
    color: #fff;
}
.lastTenCampusHeading{
    margin-bottom: 20px;
    text-align: center;
}
.line {
    display: block;
    height: 4px;
    text-align: center;
    width: 145px;
    margin: 4px auto;
    background: radial-gradient(#01774a, transparent);
    border-radius: 50%;
}
/* .placementOfficerDiv{
    width: 48%;
    border-left: 2px solid #ffffff8c;
}
.studentAccessDiv {
    width: 48%;
    border-left: 2px solid #ffffff8c;
}
.placementOfficerDiv .accessList{
    padding: 4px 0;
}
.RoleHeading span {
    font-size: 1rem;
    font-weight: 700;
}
.placementOfficerDiv .accessList ul{ 
    width: 49%;
} */
.accessListItem {
    display: flex;
    gap: 15px;
    align-items: center;
    margin: 5px 0;
    width: 48%;
    text-align: left;
}
.RoleHeading {
    padding: 5px;
    text-align: left;
    padding-left: 15px;
    width: 30%;
    background: linear-gradient(45deg, #00481a, transparent);
}
.accessListItems {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
}
.accessList img {
    width: 10%;
}
.accessListItem span {
    width: 80%;
    font-size: 15px;
    font-weight: 600;
}
.accessList {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    width: 90%;
    margin: 0 auto;
}
.slick-slider{
    margin: 10px 0;
}
.sliderSectionOne img{
    width: 80%;
    /* height: 100px; */
    aspect-ratio: 3/2;
    object-fit: contain;
    margin: 0 auto;
}
.lastTenCampus{
    padding: 10px;
    text-align: left;
    width: 95%;
    margin: 0 auto;
}
.logoNote{
    font-size: .6rem;
    color: #a7a7a7;
}
.headingName{
    color: #01774a;
    font-size: 1.2rem;
    font-weight: 500;
    /* padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom:3px solid #01774a ; */
}



/* ----------------------------------- Responsive Design ---------------------------  */
@media(min-width:320px){
    .AccessDivRoleWise {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 10px;
        background: #01774a;
        color: #fff;
    }

    .placementOfficerDiv {
        width: 100%;
        border-left: 2px solid #ffffff8c;
    }

    .RoleHeading{
        width: 90%;
    }

    .accessListItem{
        width: 100%;
    }

    .studentAccessDiv{
        width: 100%;
    }

    .homeTextDiv {
        width: 100%;
        text-align: right;
        padding: 10px 0px;
        background: linear-gradient(to right, rgba(255, 255, 255, 0.1), rgba(25, 164, 99, 0.381));
    }

    .footer-item {
        margin: 10px 0 0;
        width: 100%;
        text-align: left;
    }
}



@media(min-width:768px){
    .placementOfficerDiv{
        width: 48%;
        border-left: 2px solid #ffffff8c;
    }
    .studentAccessDiv {
        width: 48%;
        border-left: 2px solid #ffffff8c;
    }
    .placementOfficerDiv .accessList{
        padding: 4px 0;
    }
    .RoleHeading span {
        font-size: 1rem;
        font-weight: 700;
    }
    .placementOfficerDiv .accessList ul{ 
        width: 49%;
    }
}


@media(min-width:1024px){

    .accessListItem {
        align-items: center;
        display: flex;
        gap: 15px;
        margin: 5px 0;
        text-align: left;
        width: 48%;

}

}














/* ----------------------------------- Responsive Design ---------------------------  */
