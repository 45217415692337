.termsOfUseBox p {
    text-align: left;
    font-size: 14px;
    line-height: 20px;
}
.termsOfUseBox{
    width: 85%;
    margin: 10px auto;
    padding: 0 20px;
    border: 1px solid #01774a;
    border-radius: 5px;
}
.termsOfUseBox a,.activePrivacy{
    color: #01774a;
    font-weight: 600;
    text-decoration: none;
}
.termsOfUseBox h4{
    text-align: left;
    font-size: 1.2rem;
    padding: 15px 0;
}