/* src/components/AboutUs.css */
.about-us-container {
    font-family: Arial, sans-serif;
  }
  
  .about-us-header {
    background-color: #3ba549; /* Green background similar to the header */
    color: white;
    padding: 40px 20px;
    text-align: center;
  }
  
  .who-we-are {
    padding: 40px 20px;
    text-align: left;
  }
  
  .our-values {
    display: flex;
    justify-content: space-around;
    padding: 40px 20px;
    background-color: #f5f5f5;
  }
  
  .our-values > div {
    flex: 1;
  }
  
  .value, .vision, .mission {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  h2, h3 {
    color: #3ba549;
  }
  
  p {
    color: #333;
  }
  
@media(min-width:320px) {

  .our-values {
    display: flex;
    justify-content: space-around;
    padding: 40px 20px;
    background-color: #f5f5f5;
    flex-direction: column;
  }

  .value,
  .vision,
  .mission {
    margin: 10px 0;
  }

}

@media(min-width:700px) {

  .our-values {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 40px 20px;
    background-color: #f5f5f5;
  }

  .value,
  .vision,
  .mission {
    margin: 10px;
  }

}